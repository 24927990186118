import {SearchIcon} from '@chakra-ui/icons'
import {
    Box,
    Button,
    chakra,
    Input,
    Select,
    Stack,
    Td,
    Text,
    Th,
    Tr,
    VStack,
} from '@chakra-ui/react'
import {useAuth} from 'contexts/AuthContext'
import {graphql, Link} from 'gatsby'
import React, {useMemo, useRef, useState} from 'react'
import {BulletinsCatSelect, ResultsTable} from 'starterComponents'
import {Container} from 'starterUiComponents'
import TechLibraryFiles from '../../components/pdf/tech-library-file'
import PdfFirebaseUrlParser from '../../utils/pdf-firebase-url-parser'
import {
    getPermissionDeniedReason,
    hasPermission,
    PERMISSIONS,
} from '../../utils/Roles'
import useNeedsMembership from '../../hooks/useNeedsMembership'
import Loader from '../../components/ui-components/Loader'
import NotEnoughPermissions from '../../components/NotEnoughPermissions'
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
} from '@chakra-ui/react'
import useHashRouteToggle from '../../components/pdf/useHashRouteToggle'

const BulletinsPage = (props) => {
    const {
        data: {
            allMysqlBulletins: {nodes, id},
        },
    } = props


    const {currentUser, loading} = useAuth()
    const [cat, setCat] = useState(null)
    const [catDisplayName, setCatDisplayName] = useState(null)

    const [calledBy, setCalledBy] = useState(null)
    const [bulletins, setBulletins] = useState(null)

    const searchInputRef = useRef(null)

    const allBulletins = useMemo(() => {
        return nodes.map((item) => {
            const appearsin = item?.appearsin?.split(',').map((item) => item.trim())
            item = {
                ...item,
                appearsin,
            }
            return item
        })
    }, [nodes])

    React.useEffect(() => {
        const getBulletinsByCalledBy = (calledBy) => {
            return nodes.filter((item) => {
                return (
                    item?.appearsin?.includes(calledBy) &&
                    item?.usetemplate === 'PDF' &&
                    !item.displaytextheader.includes('QUICK REFERENCE')
                )
            })
        }
        setBulletins(getBulletinsByCalledBy(calledBy))
        searchInputRef?.current && (searchInputRef.current.value = '')
    }, [calledBy, nodes])

    const universalBulletins = allBulletins.filter((item) => {
        return (
            item?.appearsin?.includes('BULLETINMENU') && item.usetemplate === 'PDF'
        )
    })

    const transmissionsByCat = (cat) => {
        return nodes.filter(
            (item) =>
                item.appearsin && item.appearsin.toUpperCase().includes(cat.toUpperCase()) &&
                (item.usetemplate === 'mainmenu' || item.usetemplate === 'intermenu')
        )
    }

    const universalByCat = (cat) =>
        nodes.filter(
            (item) =>
                item.appearsin && item.appearsin.toUpperCase().includes(cat.toUpperCase()) &&
                item.usetemplate === 'PDF'
        )

    const universalArray = cat ? universalByCat(cat) : universalBulletins

    /**
     * Search Bulletins by title of transmission number
     *
     * Searches bulletins (PDF but NO quick references) by `displaytextheader` or `calledby`
     *
     * @param {SyntheticEvent} e - The submit form event
     */
    const onSearch = (e) => {
        e.preventDefault()

        const searchText = searchInputRef.current.value
        if (!searchText) return

        const _bulletins = nodes
            .filter(
                (item) =>
                    (item.calledby?.includes(searchText.toUpperCase()) ||
                        item.displaytextheader?.includes(searchText.toUpperCase().replace(/(?<!\-)(E|22)$/, '-$1')) || // Find With or without the dash on things like 4L60-E/4L60E
                        item.displaytextheader?.includes(searchText.toUpperCase().replace('-', '')) ||
                        item.displaytextheader?.includes(searchText) ||
                        item.displaytextheader?.includes(searchText.toUpperCase()) ||
                        item.displaytextheader?.includes(searchText.toLowerCase())
                    ) &&

                    item.usetemplate === 'PDF' &&
                    !item.displaytextheader?.includes('QUICK REFERENCE')
            )
            .sort((b, a) => b.calledby.localeCompare(a.calledby))

        setBulletins(_bulletins)
        setCat('')
    }

    /**
     * On Category selector click we want to empty the search bulletins input
     * and the transmission field reset.
     *
     * @param {*} cat
     */
    const onUpdateCategorySelector = (cat) => {
        setCalledBy(null)
        setCat(cat)
        searchInputRef?.current && (searchInputRef.current.value = '')
    }
    const showLoaderPage = useNeedsMembership()

    const [pdfModalInfo, setPdfModalInfo] = useState({
        title: null,
        pdfLink: null,
    })
    const [isOpen, toggleOpen] = useHashRouteToggle('#pdf')

    const openModalWithEmbeddedPDF = (e, pdfLink, title) => {
        toggleOpen(true)
        setPdfModalInfo({
            title,
            pdfLink,
        })
    }

    const closeModalWithEmbeddedPDF = (e, pdfLink, title) => {
        setPdfModalInfo({
            title: null,
            pdfLink: null,
        })
        toggleOpen(false)
    }

    const PdfTdAnchor = ({pdfLink, anchorText, modalTitle}) => {
        return (
            <a
                onClick={(e) => openModalWithEmbeddedPDF(e, pdfLink, modalTitle)}
                style={{cursor: 'pointer'}}
            >
                {anchorText}
            </a>
        )
    }
    const renderTechLibraryItem = (item) => {
        // const rel = PdfFirebaseUrlParser.getUrl(item.dataUrl)
        // const pdfLink = `/files/tech-library/${rel}`
        const pdfLink = PdfFirebaseUrlParser.getUrl(item.dataUrl)
        return (
            <Tr key={item.id} sx={{'&:first-of-type': {pt: 5}}}>
                <Td w={{base: '200px', md: '150px'}} className="text-sm uppercase">
                    <PdfTdAnchor
                        pdfLink={pdfLink}
                        anchorText={item.calledby}
                        modalTitle={item.displaytextheader}
                    />
                </Td>
                <Td w="150px" fontSize="sm">
                    <PdfTdAnchor
                        pdfLink={pdfLink}
                        anchorText={item.returntotext2}
                        modalTitle={item.displaytextheader}
                    />
                </Td>
                <Td w={{base: '400px', md: '500px'}} fontSize="sm">
                    <PdfTdAnchor
                        pdfLink={pdfLink}
                        anchorText={item.displaytextheader}
                        modalTitle={item.displaytextheader}
                    />
                </Td>
                <Td w="150px" fontSize="sm">
                    <PdfTdAnchor
                        pdfLink={pdfLink}
                        anchorText={item.type}
                        modalTitle={item.displaytextheader}
                    />
                </Td>
            </Tr>
        )
    }

    const onUpdateCategoryDisplayName = (catName) => setCatDisplayName(catName)
    if (!hasPermission(currentUser, PERMISSIONS.TECH_LIBRARY) && !loading) {
        const {reason} = getPermissionDeniedReason(
            currentUser,
            PERMISSIONS.MEMBERSHIP_CALLS
        )
        return <NotEnoughPermissions reason={reason}/>
    }

    if (showLoaderPage) {
        return <Loader/>
    }

    return (
        <Container>
            {/* Search bulletins */}
            <Box
                pb="60px"
                pt="40px"
                borderRadius="20px"
                backgroundImage="url(/backpattern.jpg)"
                backgroundSize="cover"
                mb={10}
            >
                <chakra.h1 className="mb-8 text-center text-white uppercase text-l md:text-xxl ">
                    Search Tech Library
                </chakra.h1>
                <form onSubmit={onSearch}>
                    <Container size="md">
                        <Stack spacing={3} direction={{base: 'column', md: 'row'}}>
                            <Input
                                type="text"
                                placeholder="Search by Title or Transmission Number "
                                ref={searchInputRef}
                            />
                            <Button type="submit" variant="outline" leftIcon={<SearchIcon/>}>
                                Search
                            </Button>
                        </Stack>
                    </Container>
                </form>
            </Box>
            {/* Selects */}
            <Stack
                direction={{base: 'column', md: 'row'}}
                className="justify-between font-bold text-l font-heading"
            >
                <Text className="font-bold text-l font-heading">
                    Find Bulletins by Make
                </Text>
                <Box
                    sx={{a: {textDecoration: 'underline'}}}
                    style={{fontSize: '1.15rem'}}
                >
                    <chakra.span className="font-normal text-md">Go to:</chakra.span>
                    {' '}
                    <Link to="/quick-references">Quick References</Link> |{' '}
                    <Link to="/numeric-references">Numeric Reference</Link> |{' '}
                    <Link to="/quarterly-bulletins">Quarterly Bulletins</Link>
                </Box>
            </Stack>
            <Stack
                direction={{base: 'column', md: 'row'}}
                spacing={5}
                className="p-8 mb-5 bg-lightBlue"
                sx={{
                    P: {color: 'white', fontStyle: 'italic', m: 0},
                    '.chakra-stack': {
                        width: {base: '100%', md: '50%'},
                        alignItems: 'flex-start',
                    },
                }}
            >
                <VStack>
                    <Text>Select a Make</Text>
                    <BulletinsCatSelect
                        setCategory={onUpdateCategorySelector}
                        setDisplayCategoryName={onUpdateCategoryDisplayName}
                        selectedCategory={cat}
                    />
                </VStack>
                {cat && (
                    <VStack>
                        <Text>Select a Transmission</Text>
                        <Select
                            placeholder="Select a transmission"
                            layerStyle="input"
                            onChange={(e) => setCalledBy(e.target.value)}
                        >
                            {transmissionsByCat(cat).map((item) => {
                                return (
                                    <option key={item.id} value={item.calledby}>
                                        {item.displaytextlink}
                                    </option>
                                )
                            })}
                        </Select>
                    </VStack>
                )}
            </Stack>

            {/* Results */}
            {searchInputRef?.current?.value && !!bulletins.length && (
                <Text className="text-sm font-bold">
                    Results for "{searchInputRef.current.value}"
                </Text>
            )}
            {(!bulletins || !bulletins.length) && searchInputRef?.current?.value && (
                <Text className="text-sm font-bold">
                    No results for "{searchInputRef.current.value}"
                </Text>
            )}
            <VStack
                spacing={10}
                fontSize="sm"
                fontWeight="normal"
                lineHeight={1.6}
                sx={{a: {fontSize: 'sm', fontWeight: 'normal', lineHeight: 1.6}}}
            >
                {bulletins && bulletins.length && (
                    <ResultsTable
                        sx={{tr: {display: 'block'}}}
                        headers={
                            <>
                                <Th w="150px">Number</Th>
                                <Th w="150px">Make</Th>
                                <Th w={{base: '400px', md: '500px'}}>description</Th>
                                <Th w="150px">Type</Th>
                            </>
                        }
                    >
                        {bulletins &&
                            bulletins.map((item) => {
                                return renderTechLibraryItem(item)
                            })}
                    </ResultsTable>
                )}

                {/* Universal results */}

                {universalArray && universalArray.length > 0 && (
                    <Box
                        className="universal"
                        border="1px solid #ddd"
                        p={4}
                        w="100%"
                        mt={5}
                    >
                        <Text className="text-sm font-bold uppercase">
                            Universal {catDisplayName}
                        </Text>
                        <ResultsTable
                            key={id}
                            sx={{tr: {display: 'block'}}}
                            headers={
                                <>
                                    <Th w="150px">Number</Th>
                                    <Th w="150px">Make</Th>
                                    <Th w={{base: '400px', md: '500px'}}>description</Th>
                                    <Th w="150px">Type</Th>
                                </>
                            }
                        >
                            {universalArray &&
                                universalArray.map((item) => {
                                    return renderTechLibraryItem(item)
                                })}
                        </ResultsTable>
                    </Box>
                )}
            </VStack>
            {/* PDF Modal */}
            <Modal
                size="full"
                isOpen={isOpen}
                onClose={closeModalWithEmbeddedPDF}
                className="m-4"
            >
                <ModalContent className="my-0 ">
                    <ModalHeader
                        className="font-bold text-white bg-blue font-heading"
                        style={{fontSize: '1.2em'}}
                    >
                        {pdfModalInfo?.title}
                    </ModalHeader>
                    <ModalCloseButton color="white" style={{fontSize: '24px'}}/>
                    <ModalBody
                        className="flex justify-center content-center h-full p-0 m-0"
                        style={{padding: '0px', margin: '0px'}}
                    >
                        {pdfModalInfo?.pdfLink && (
                            <TechLibraryFiles url={pdfModalInfo?.pdfLink}/>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Container>
    )
}

export default BulletinsPage

export const techLibraryQuery = graphql`
  query {
    allMysqlBulletins(
      sort: { fields: [disporder, displaytextlink], order: ASC }
    ) {
      nodes {
        appearsin
        calledby
        usetemplate
        displaytextlink
        tempFirebaseMedialink
        displaytextheader
        id
        dataUrl
        returntotext2
        type
      }
    }
  }
`
